import React from "react"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import track from "../../../components/track"
import heroDosing from "../../../images/hero-hcp-dosing.png"
import heroDosingLarge from "../../../images/hero-hcp-dosing-large.png"
import heroDosingMobile from "../../../images/hero-hcp-dosing-mobile.png"
import chartDosingOne from "../../../images/chart-dosing1.png"
import chartDosingTwo from "../../../images/chart-dosing2.png"
import iconSunrise from "../../../images/icon-circle-sunrise.png"
import iconSilverware from "../../../images/icon-circle-silverware.png"
import iconCalendar from "../../../images/icon-circle-calendar.png"

const Dosing = () => {

  const pageTitle = 'Dosing';
  const pageDescription = 'Learn about dosing options with MOVANTIK 12.5 mg and 25 mg tablets.';
  const pageName = "Dosing";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="hcp" pageSection="hcp-dosing" pageClass="dosing" pageName={pageName}>
      <div className="page-title">
        <div className="container">Dosing</div>
      </div>
      <div className="hero">
        <img src={heroDosingLarge} className="hero-img large" alt="" />
        <img src={heroDosing} className="hero-img medium" alt="" />
        <img src={heroDosingMobile} className="hero-img mobile" alt="" />
        <div className="container">
          <div className="headlines">
            <h1>Convenient dosing with 2 options<sup>1</sup></h1>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="columns three">
          <div className="column left-column">
            <img src={iconSunrise} className="icon" alt="icon" />
            <p>One tablet, once-daily in the AM</p>
          </div>
          <div className="column middle-column">
            <img src={iconSilverware} className="icon" alt="icon" />
            <p>On an empty stomach (1 hour before breakfast or 2 hours after)</p>
          </div>
          <div className="column right-column">
            <img src={iconCalendar} className="icon" alt="icon" />
            <p>For the duration of opioid treatment</p>
          </div>
        </div>
        <h2>For a more personalized treatment, consider the 2 dosing strengths of MOVANTIK<sup>1</sup>:</h2>
        <ul>
          <li>2 dosing strengths—25 mg and 12.5 mg—allow for flexibility and convenient once-daily dosing. Take on an empty stomach<sup>1</sup></li>
        </ul>
        <div className="center">
          <Link to="/hcp/efficacy/" id="hcp-dosing-btn-am" className="button" onClick={() => track("HCP Dosing page", "button click", "HCP Dosing-AM to PM Button")}>
            <div className="btn-text">Explore rapid response with <span className="bold">MOVANTIK&mdash;AM to PM</span><sup>1</sup></div>
            <div className="btn-arrow"></div>
          </Link>
        </div>
        <div className="end-matter">
        {/* <div className="footnotes">
            <div className="footnote asterisk">*At least 1 hour prior to the first meal of the day or 2&nbsp;hours after the meal. Consumption of grapefruit or grapefruit juice during treatment with MOVANTIK is to be avoided.<sup>1</sup></div>
          </div> */}

          <div className="references"><span className="bold">Reference: 1.</span>&nbsp;MOVANTIK<sup>&reg;</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023.</div>
        </div>
      </div>
    </Layout>
  )
}

export default Dosing
